main {
  min-height: 80vh;
}

#navLogo {
  height: 20px;
  margin-bottom: 4px;
  margin-right: 3.5px;
}

#releaseCard {
  background-color: black;
}

#releaseCard:hover {
  background-color: white;
}

#faIcon {
  color: rgb(246, 135, 168);
}

#faIcon:hover {
  color: white;
}

#buyButton {
  background-color: rgb(246, 135, 168);
  color: black;
}

#backButton {
  background-color: rgb(26, 99, 132);
  color: rgb(246, 135, 168);
}

#backButton:hover,
#buyButton:hover {
  background-color: white;
  color: black;
}

#heroMain {
  max-height: 510px;
}

#heroCaption {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 30px;
  top: 45px;
  bottom: auto;
}

#paginatorContainer {
  background-color: rgb(246, 135, 168);
}

#paginatorItem {
  background-color: black;
  color: rgb(246, 135, 168);
  margin-left: 6px;
}

#paginatorSelected {
  background-color: rgb(246, 135, 168);
  color: black;
  margin-left: 6px;
}
